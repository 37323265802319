
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import SearchList from "@/ComponentsSystem/Production-history/SearchList.vue";
import Data from "@/data/Production-history/Data.json";
import Info from "@/data/Production-history/Info.json";
import Detail from "@/data/Production-history/Detail.json";

// 設定要導入的data格式;
import {
  NewClientData,
  NewMaterialsData,
  NewQuantityData,
  NewWarehousingData,
  NewProcessData,
  NewInspectionData,
} from "@/core/data/products";

export default defineComponent({
  name: "overview",
  components: { SearchList },
  data(): {
    DateType: string;
    startDate: string;
    endDate: string;
    Data: any;
    Info: any;
    Detail: any;
    Type: string;
  } {
    return {
      DateType: "",
      startDate: "",
      endDate: "",
      Type: "",
      Data: Data,
      Info: Info,
      Detail: Detail,
    };
  },
  setup() {
    // 導入資料
    const Client = ref<NewClientData>({
      ID: "",
      Name: "",
      Number: "",
      OrderDate: "",
      Quantity: "",
      Sales: "",
    });
    const Material = ref<NewMaterialsData>({
      Name: "",
      Number: "",
      Attributes: "",
      Material: "",
      Unit: "",
    });
    const Quantity = ref<NewQuantityData>({
      Project: "",
      Specification: "",
      Methods: "",
      Proportion: "",
      Characteristic: "",
    });
    const Warehousing = ref<NewWarehousingData>({
      Number: "",
      Name: "",
      PurchaseDate: "",
      Quantity: "",
      Mark: "",
    });
    const Process = ref<NewProcessData>({
      ID: "",
      Responsibility: "",
      Number: "",
      ShipDate: "",
    });
    const Inspection = ref<NewInspectionData>({
      Person: "",
      MeasuringNumber: "",
      MeasuringDate: "",
      MeasuringMethod: "",
      MeasuringQuantity: "",
      MeasuringItem: "",
      MeasuringAtion: "",
      QualifiedNumber: "",
      Determination: "",
    });
    onMounted(() => {
      setCurrentPageBreadcrumbs("查詢生產履歷", ["生產履歷溝通數位化系統"]);
    });
    return {
      Client,
      Material,
      Quantity,
      Warehousing,
      Process,
      Inspection,
    };
  },
});
